import client from '../../axios-client'
import type { RequestConfig, ResponseErrorConfig } from '../../axios-client'
import type { PostOrdersReportMutationResponse, PostOrdersReportQueryParams, PostOrdersReport400 } from '../entities/PostOrdersReport'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const postOrdersReportMutationKey = () => [{ url: '/orders/report' }] as const

export type PostOrdersReportMutationKey = ReturnType<typeof postOrdersReportMutationKey>

/**
 * @description Получение отчета по поездкам Dostavkee
 * @summary Получение отчета по поездкам Dostavkee
 * {@link /orders/report}
 */
async function postOrdersReport(params: PostOrdersReportQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<PostOrdersReportMutationResponse, ResponseErrorConfig<PostOrdersReport400>, unknown>({
    method: 'POST',
    url: `/orders/report`,
    params,
    ...config,
  })
  return res.data
}

/**
 * @description Получение отчета по поездкам Dostavkee
 * @summary Получение отчета по поездкам Dostavkee
 * {@link /orders/report}
 */
export function usePostOrdersReport(
  options: {
    mutation?: UseMutationOptions<PostOrdersReportMutationResponse, ResponseErrorConfig<PostOrdersReport400>, { params: PostOrdersReportQueryParams }>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? postOrdersReportMutationKey()

  return useMutation<PostOrdersReportMutationResponse, ResponseErrorConfig<PostOrdersReport400>, { params: PostOrdersReportQueryParams }>({
    mutationFn: async ({ params }) => {
      return postOrdersReport(params, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}