import type { ErrorStruct } from './ErrorStruct'
import type { ServiceGetOrders } from './service/GetOrders'

export enum GetOrdersSearchQueryParamsStages {
  'searching' = 'searching',
  'courierAccepted' = 'courierAccepted',
  'courierArrived' = 'courierArrived',
  'courierPickedUp' = 'courierPickedUp',
  'orderCompleted' = 'orderCompleted',
  'courierCanceled' = 'courierCanceled',
  'clientCanceled' = 'clientCanceled',
  'canceledByTimeout' = 'canceledByTimeout',
  'clientArchived' = 'clientArchived',
}

export type GetOrdersSearchQueryParams = {
  /**
   * @description ID адреса
   * @type string | undefined
   */
  address_id?: string
  /**
   * @description int limit
   * @default 10
   * @type integer
   */
  limit: number
  /**
   * @description int offset
   * @default 0
   * @type integer
   */
  offset: number
  /**
   * @description Дата в формате DD-MM-YYYY
   * @type string | undefined
   */
  start_date?: string
  /**
   * @description Дата в формате DD-MM-YYYY
   * @type string | undefined
   */
  end_date?: string
  /**
   * @description Стадии заказов
   * @type array | undefined
   */
  stages?: GetOrdersSearchQueryParamsStages[]
  /**
   * @description Смещение часового пояса от UTC в минутах
   * @default 0
   * @type integer
   */
  timezone_offset_minutes: number
}

/**
 * @description OK
 */
export type GetOrdersSearch200 = ServiceGetOrders

/**
 * @description Bad Request
 */
export type GetOrdersSearch400 = ErrorStruct

export type GetOrdersSearchQueryResponse = GetOrdersSearch200

export type GetOrdersSearchQuery = {
  Response: GetOrdersSearch200
  QueryParams: GetOrdersSearchQueryParams
  Errors: GetOrdersSearch400
}