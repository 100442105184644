import React, { FC } from 'react'

import { IconSvgProps } from './icon'

export const IconDownload: FC<IconSvgProps> = ({
    size = 24,
    color = 'var(--text-and-icon-primary)',
}) => {
    return (
        <svg
            fill='none'
            height={size}
            viewBox='0 0 24 24'
            width={size}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M10.8748 3.11112V13.7627L7.16789 10.1033C6.72913 9.66936 6.02048 9.66936 5.58173 10.1033C5.13735 10.5371 5.13735 11.2405 5.58173 11.6744L11.168 17.1917C11.3741 17.4153 11.6711 17.5556 11.9999 17.5556C12.329 17.5556 12.6263 17.415 12.8325 17.1909L18.4179 11.6744C18.8623 11.2405 18.8623 10.5371 18.4179 10.1033C17.9792 9.66936 17.2705 9.66936 16.8318 10.1033L13.1249 13.7626V3.11112C13.1249 2.49722 12.6187 2 11.9999 2C11.3811 2 10.8748 2.49722 10.8748 3.11112Z'
                fill={color}
            />
            <path
                d='M19.875 21.9999H4.125C3.50625 21.9999 3 21.5027 3 20.8889C3 20.275 3.50625 19.7778 4.125 19.7778H19.875C20.4937 19.7778 21 20.275 21 20.8889C21 21.5027 20.4937 21.9999 19.875 21.9999Z'
                fill={color}
            />
        </svg>
    )
}
