import React from 'react'
import { ErrorStruct } from '@dostavkee/contracts-console/entities'
import { IconCheck, IconClose, InputPhone, Spinner } from '@dostavkee/web-ui'
import * as Sentry from '@sentry/react'
import { Controller, ControllerFieldState, useFormContext } from 'react-hook-form'

import { useEmployeeCheck } from '@/corpdrivee/entities/employee'
import { ERROR_CODES } from '@/shell/shared/config'

import { EmployeeFormValues } from './employee-form'

const PHONE_TEXT_MAP = new Map<number, string>([
    [ERROR_CODES.EMPLOYEE_ALREADY_EXISTS_ERROR_CODE, 'Пользователь уже добавлен'],
    [
        ERROR_CODES.EMPLOYEE_DRIVEE_USER_ALREADY_BOUND_TO_COMPANY_ERROR_CODE,
        'Пользователь уже добавлен в другой организации',
    ],
    [
        ERROR_CODES.EMPLOYEE_DRIVEE_USER_NOT_FOUND_ERROR_CODE,
        'Пользователя с таким номером нет в Drivee',
    ],
])

const getErrorMessage = (error: unknown) => {
    const errorStruct = error as ErrorStruct
    if (errorStruct?.error_code && PHONE_TEXT_MAP.has(errorStruct.error_code)) {
        return PHONE_TEXT_MAP.get(errorStruct.error_code)
    }

    Sentry.captureException(error)

    return 'Произошла неизвестная ошибка. Пожалуйста, обратитесь в службу технической поддержки'
}

export const EmployeeFormPhone = () => {
    const { mutateAsync, isPending } = useEmployeeCheck()
    const { control, formState, trigger } = useFormContext<EmployeeFormValues>()

    const defaultPhone = formState.defaultValues?.phone

    const getPostfixElement = (fieldState: ControllerFieldState, value: string) => {
        if (isPending) {
            return <Spinner />
        }

        if (fieldState.error?.message) {
            return <IconClose color='var(--extensions-text-and-icon-error)' />
        }

        if (value?.length === 11) {
            return <IconCheck color='var(--text-and-icon-accent)' />
        }

        return null
    }

    return (
        <Controller
            control={control}
            name='phone'
            render={({ field, fieldState }) => (
                <InputPhone
                    autoComplete='off'
                    disabled={defaultPhone?.length === 11}
                    errorMessage={fieldState.error?.message}
                    id='phone'
                    isInvalid={fieldState.invalid}
                    label='Телефон'
                    postfixEl={getPostfixElement(fieldState, field.value)}
                    {...field}
                    onChange={(value) => {
                        field.onChange(value)
                        if (value?.length === 11) {
                            trigger('phone')
                        }
                    }}
                />
            )}
            rules={{
                required: 'Обязательное поле',
                minLength: {
                    value: 11,
                    message: 'Некорректный номер телефона',
                },
                validate: async (value) => {
                    if (defaultPhone && value === defaultPhone) {
                        return true
                    }

                    if (value?.length === 11) {
                        try {
                            const response = await mutateAsync({ data: { phone: value } })

                            if (response.error) {
                                throw new Error(response.error)
                            }

                            return true
                        } catch (error) {
                            return getErrorMessage(error)
                        }
                    }

                    return true
                },
            }}
        />
    )
}
