import client from '../../axios-client'
import type { RequestConfig, ResponseErrorConfig } from '../../axios-client'
import type { PostEmployeesCheckMutationRequest, PostEmployeesCheckMutationResponse, PostEmployeesCheck400 } from '../entities/PostEmployeesCheck'
import type { UseMutationOptions } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

export const postEmployeesCheckMutationKey = () => [{ url: '/employees/check' }] as const

export type PostEmployeesCheckMutationKey = ReturnType<typeof postEmployeesCheckMutationKey>

/**
 * @description Проверка номера сотрудника в Drivee
 * @summary Проверка номера сотрудника в Drivee
 * {@link /employees/check}
 */
async function postEmployeesCheck(data: PostEmployeesCheckMutationRequest, config: Partial<RequestConfig<PostEmployeesCheckMutationRequest>> = {}) {
  const res = await client<PostEmployeesCheckMutationResponse, ResponseErrorConfig<PostEmployeesCheck400>, PostEmployeesCheckMutationRequest>({
    method: 'POST',
    url: `/employees/check`,
    data,
    ...config,
  })
  return res.data
}

/**
 * @description Проверка номера сотрудника в Drivee
 * @summary Проверка номера сотрудника в Drivee
 * {@link /employees/check}
 */
export function usePostEmployeesCheck(
  options: {
    mutation?: UseMutationOptions<PostEmployeesCheckMutationResponse, ResponseErrorConfig<PostEmployeesCheck400>, { data: PostEmployeesCheckMutationRequest }>
    client?: Partial<RequestConfig<PostEmployeesCheckMutationRequest>>
  } = {},
) {
  const { mutation: mutationOptions, client: config = {} } = options ?? {}
  const mutationKey = mutationOptions?.mutationKey ?? postEmployeesCheckMutationKey()

  return useMutation<PostEmployeesCheckMutationResponse, ResponseErrorConfig<PostEmployeesCheck400>, { data: PostEmployeesCheckMutationRequest }>({
    mutationFn: async ({ data }) => {
      return postEmployeesCheck(data, config)
    },
    mutationKey,
    ...mutationOptions,
  })
}