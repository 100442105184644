import React, { FC } from 'react'

import { IconSvgProps } from './icon'

export const IconCalendar: FC<IconSvgProps> = ({
    size = 24,
    color = 'var(--text-and-icon-primary)',
}) => {
    return (
        <svg
            fill='none'
            height={size}
            viewBox='0 0 24 24'
            width={size}
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M9 12C9 12.5525 8.54997 13 7.99994 13C7.44991 13 6.99988 12.5525 6.99988 12C6.99988 11.4475 7.44991 11 7.99994 11C8.54997 11 9 11.4475 9 12Z'
                fill={color}
            />
            <path
                d='M9 16C9 15.4475 8.54997 15 7.99994 15C7.44991 15 6.99988 15.4475 6.99988 16C6.99988 16.5524 7.44991 16.9999 7.99994 16.9999C8.54997 16.9999 9 16.5524 9 16Z'
                fill={color}
            />
            <path
                d='M12.9999 15.9999C12.9999 16.5524 12.5499 16.9999 11.9998 16.9999C11.4498 16.9999 10.9998 16.5524 10.9998 15.9999C10.9998 15.4474 11.4498 15 11.9998 15C12.5499 15 12.9999 15.4474 12.9999 15.9999Z'
                fill={color}
            />
            <path
                d='M16.9999 15.9999C16.9999 16.5524 16.5499 16.9999 15.9999 16.9999C15.4499 16.9999 14.9999 16.5524 14.9999 15.9999C14.9999 15.4474 15.4499 15 15.9999 15C16.5499 15 16.9999 15.4474 16.9999 15.9999Z'
                fill={color}
            />
            <path
                d='M12.9999 12C12.9999 12.5525 12.5499 13 11.9998 13C11.4498 13 10.9998 12.5525 10.9998 12C10.9998 11.4475 11.4498 11 11.9998 11C12.5499 11 12.9999 11.4475 12.9999 12Z'
                fill={color}
            />
            <path
                d='M16.9999 12C16.9999 12.5525 16.5499 13 15.9999 13C15.4499 13 14.9999 12.5525 14.9999 12C14.9999 11.4475 15.4499 11 15.9999 11C16.5499 11 16.9999 11.4475 16.9999 12Z'
                fill={color}
            />
            <path
                clipRule='evenodd'
                d='M18.9999 4H16.9999V2.99999C16.9999 2.4475 16.5499 2 15.9999 2C15.4499 2 14.9999 2.4475 14.9999 2.99999V4H9V2.99999C9 2.4475 8.54997 2 7.99994 2C7.44991 2 6.99988 2.4475 6.99988 2.99999V4H4.99998C3.34499 4.0005 2 5.343 2 7V19C2 20.657 3.34499 21.9995 4.99998 22H18.9999C20.6549 21.9995 21.9999 20.657 21.9999 19V7C21.9999 5.343 20.6549 4.0005 18.9999 4ZM19.9999 8H4L3.99999 19C3.99999 19.2795 4.11003 19.523 4.29503 19.707C4.47503 19.8895 4.71998 19.9995 4.99998 20H18.9999C19.2799 19.9995 19.5249 19.8895 19.7048 19.707C19.8898 19.523 19.9999 19.2795 19.9999 19V8Z'
                fill={color}
                fillRule='evenodd'
            />
        </svg>
    )
}
