import { createFileRoute } from '@tanstack/react-router'

import { GetOrdersSearchStage } from '@/dostavkee/pages/orders-search-page'

export const Route = createFileRoute('/_dashboard/dostavkee/_sidebar-layout/orders/_tabs/search')({
    onEnter: () => {
        document.title = 'Dostavkee - Поиск'
    },
    validateSearch: (
        search: Record<string, unknown>
    ): {
        address_id?: string
        address_name?: string
        page?: number
        start_date?: string
        end_date?: string
        stages?: GetOrdersSearchStage[]
    } => {
        return {
            page: (search.page as number) ?? 1,
            stages: search.stages as GetOrdersSearchStage[],
            start_date: search.start_date as string,
            end_date: search.end_date as string,
            address_id: search.address_id as string,
            address_name: search.address_name as string,
        }
    },
})
