import client from '../../axios-client'
import type { RequestConfig, ResponseErrorConfig } from '../../axios-client'
import type {
  GetCompaniesCompanyIdAddressesSearchQueryResponse,
  GetCompaniesCompanyIdAddressesSearchPathParams,
  GetCompaniesCompanyIdAddressesSearchQueryParams,
  GetCompaniesCompanyIdAddressesSearch400,
} from '../entities/GetCompaniesCompanyIdAddressesSearch'

export function getGetCompaniesCompanyIdAddressesSearchUrl(company_id: GetCompaniesCompanyIdAddressesSearchPathParams['company_id']) {
  return `/companies/${company_id}/addresses/search` as const
}

/**
 * @description Поиск адресов по названию
 * @summary Поиск адресов по названию
 * {@link /companies/:company_id/addresses/search}
 */
export async function getCompaniesCompanyIdAddressesSearch(
  company_id: GetCompaniesCompanyIdAddressesSearchPathParams['company_id'],
  params?: GetCompaniesCompanyIdAddressesSearchQueryParams,
  config: Partial<RequestConfig> = {},
) {
  const res = await client<GetCompaniesCompanyIdAddressesSearchQueryResponse, ResponseErrorConfig<GetCompaniesCompanyIdAddressesSearch400>, unknown>({
    method: 'GET',
    url: getGetCompaniesCompanyIdAddressesSearchUrl(company_id).toString(),
    params,
    ...config,
  })
  return res.data
}