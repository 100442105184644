import React, {
    ChangeEvent,
    FocusEvent,
    ForwardedRef,
    forwardRef,
    InputHTMLAttributes,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import { useDebounce, useOnClickOutside } from '@dostavkee/react-hooks'
import { createPortal } from 'react-dom'

import { IconClearFilled } from '../icon/icon-clear-filled'
import { IconSearch } from '../icon/icon-search'
import { Input, InputProps } from '../input'
import { Spinner } from '../spinner'
import styles from './autocomplete.module.scss'

interface Rect {
    top: number
    left: number
    width: number
}

export interface AutocompleteOption<T extends {}> {
    id: string | number
    name: string
    payload?: T
    hint?: string
}

const INPUT_SIZE_TO_PX: Record<NonNullable<InputProps['size']>, number> = {
    small: 48,
    medium: 56,
}

export interface AutocompleteProps<T extends {}>
    extends Omit<InputProps, 'onSelect' | 'value'>,
        Omit<InputHTMLAttributes<HTMLInputElement>, 'onSelect' | 'value' | 'size'> {
    loadOptions: (inputValue: string) => Promise<AutocompleteOption<T>[]>
    onSelect: (option?: AutocompleteOption<T>) => void
    value?: AutocompleteOption<T>['name']
    footer?: (isOpen: boolean, setIsOpen: (isOpen: boolean) => void) => ReactNode
    noResult?: ReactNode | null
    isSelectedRequired?: boolean
    getInjectedEl?: () => HTMLElement
    mode?: 'portaled' | 'absolute'
    onStartOpen?: () => void
    onStartClose?: () => void
}

export function AutocompleteInner<T extends {}>(
    {
        loadOptions,
        onSelect,
        errorMessage,
        isInvalid,
        value,
        onFocus,
        footer,
        noResult = null,
        isSelectedRequired = false,
        getInjectedEl,
        mode = 'portaled',
        onStartOpen,
        onStartClose,
        size = 'medium',
        ...props
    }: AutocompleteProps<T>,
    ref: ForwardedRef<HTMLInputElement>
) {
    const [search, setSearch] = useState<string>('')
    const debouncedSearch = useDebounce<string>(search, 500)
    const [options, setOptions] = useState<AutocompleteOption<T>[]>([])
    const [isOpen, setIsOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const anchorRef = useRef<HTMLDivElement>(null)
    const listRef = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const [anchorRect, setAnchorRect] = useState<Rect | undefined>()

    const updateSelectOptions = useCallback(
        async (value: string) => {
            try {
                setIsLoading(true)
                const options = await loadOptions(value)
                setOptions(options ?? [])
            } catch (error) {
                console.log(error)
            } finally {
                setIsLoading(false)
            }
        },
        [loadOptions]
    )

    const handleSelectOption = useCallback(
        (option: AutocompleteOption<T>) => {
            setSearch('')
            onSelect(option)
            setIsOpen(false)

            if (typeof onStartClose === 'function') {
                onStartClose()
            }
        },
        [onSelect, onStartClose]
    )

    const handleChangeSearch = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setSearch(event.currentTarget.value)

            /**
             * Reset selected option
             */
            onSelect()
        },
        [onSelect]
    )

    const handleFocusSearch = useCallback(
        (event: FocusEvent<HTMLInputElement>) => {
            if (typeof onFocus === 'function') {
                onFocus(event)
            }

            if (anchorRef.current) {
                const rect = anchorRef.current.getBoundingClientRect()
                setAnchorRect({
                    top: rect.y + window.scrollY,
                    left: rect.x,
                    width: rect.width,
                })
            }
            setIsOpen(true)

            if (typeof onStartOpen === 'function') {
                onStartOpen()
            }
        },
        [onFocus, onStartOpen]
    )

    useEffect(() => {
        if (!ref) {
            return
        }

        if (typeof ref === 'function') {
            ref(inputRef.current)
        } else {
            ref.current = inputRef.current
        }
    }, [ref])

    useEffect(() => {
        if (debouncedSearch) {
            updateSelectOptions(debouncedSearch)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearch])

    useOnClickOutside(listRef, () => {
        if (isSelectedRequired) {
            setSearch('')
        }
        setOptions([])
        setIsOpen(false)
        onStartClose && onStartClose()
    })

    const renderSuggestionList = React.useCallback(() => {
        return (
            <>
                {options?.length
                    ? options!.map((option) => (
                          <div
                              key={option.id}
                              className={styles['suggestion-list__item']}
                              role='button'
                              onClick={() => {
                                  handleSelectOption(option)
                              }}
                          >
                              {option.name}
                              {option.hint && (
                                  <div className={styles['suggestion-list__item-hint']}>
                                      {option.hint}
                                  </div>
                              )}
                          </div>
                      ))
                    : noResult}
                {typeof footer === 'function' && footer(isOpen, setIsOpen)}
            </>
        )
    }, [footer, handleSelectOption, isOpen, noResult, options])

    return (
        <div className={styles['suggestions-wrapper']}>
            <div ref={anchorRef}>
                <Input
                    ref={inputRef}
                    autoComplete='off'
                    size={size}
                    postfixEl={
                        isLoading ? (
                            <Spinner size='small' />
                        ) : value ? (
                            <div
                                role='button'
                                style={{ cursor: 'pointer', height: 22 }}
                                onClick={(event) => {
                                    event.preventDefault()
                                    event.stopPropagation()
                                    onSelect()
                                    setSearch('')
                                    setOptions([])
                                    inputRef.current?.focus()
                                }}
                            >
                                <IconClearFilled color='var(--text-and-icon-secondary)' size={22} />
                            </div>
                        ) : (
                            <IconSearch color='var(--text-and-icon-secondary)' size={22} />
                        )
                    }
                    {...props}
                    errorMessage={errorMessage}
                    isInvalid={isInvalid}
                    label={props.label}
                    value={value || search}
                    onChange={handleChangeSearch}
                    onFocus={handleFocusSearch}
                />
                {!isLoading &&
                    isOpen &&
                    debouncedSearch &&
                    anchorRect &&
                    (() => {
                        const { top, left, width } = anchorRect

                        if (mode === 'portaled') {
                            return createPortal(
                                <div
                                    ref={listRef}
                                    className={styles['suggestion-list']}
                                    style={{
                                        top: top + INPUT_SIZE_TO_PX[size] + 8,
                                        left,
                                        width,
                                    }}
                                >
                                    {renderSuggestionList()}
                                </div>,
                                getInjectedEl ? getInjectedEl() : document.body
                            )
                        }

                        if (mode === 'absolute') {
                            return (
                                <div ref={listRef} className={styles['suggestion-list']}>
                                    {renderSuggestionList()}
                                </div>
                            )
                        }
                    })()}
            </div>
        </div>
    )
}

export const Autocomplete = forwardRef(AutocompleteInner) as <T extends {}>(
    props: AutocompleteProps<T> & { ref?: React.ForwardedRef<HTMLUListElement> }
) => ReturnType<typeof AutocompleteInner>
