import React, { FC } from 'react'

import { Button } from '../button'
import { IconClose } from '../icon/icon-close'
import styles from './bottom-sheet.module.scss'

interface BottomSheetHeaderProps {
    children: React.ReactNode
    onClose?: () => void
}

export const BottomSheetHeader: FC<BottomSheetHeaderProps> = ({ children, onClose }) => {
    return (
        <header className={styles['bottom-sheet__header']}>
            <div className={styles['bottom-sheet__header_container']}>
                <div className={styles['bottom-sheet__header_container_prefix']} />

                <div className={styles['bottom-sheet__header_container_content']}>{children}</div>

                <div className={styles['bottom-sheet__header_container_postfix']}>
                    {typeof onClose === 'function' && (
                        <Button
                            autoFocus={false}
                            prefixEl={<IconClose size={24} />}
                            variant='ghost'
                            onClick={onClose}
                        />
                    )}
                </div>
            </div>
        </header>
    )
}
