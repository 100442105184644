import React from 'react'
import { BottomSheet } from '@dostavkee/web-ui'

import { OrdersSearchForm } from '@/dostavkee/pages/orders-search-page'

// import { RideOrdersFilterForm } from '../ride-orders-filter-form/ride-orders-filter-form'

interface OrdersFiltersBottomSheetProps {
    isOpen: boolean
    onClose: () => void
}

export const OrdersFiltersBottomSheet: React.FC<OrdersFiltersBottomSheetProps> = ({
    isOpen,
    onClose,
}) => {
    const [isExpand, setIsExpand] = React.useState(true)

    return (
        <BottomSheet
            expandOnContentDrag={isExpand}
            initialFocusRef={false}
            open={isOpen}
            onDismiss={onClose}
        >
            <BottomSheet.Header onClose={onClose}>Фильтр</BottomSheet.Header>
            <BottomSheet.Container>
                <OrdersSearchForm
                    onClose={onClose}
                    onStartCloseSearch={() => {
                        setIsExpand(true)
                    }}
                    onStartOpenSearch={() => {
                        setIsExpand(false)
                    }}
                />
            </BottomSheet.Container>
        </BottomSheet>
    )
}
