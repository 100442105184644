import React, { FC } from 'react'
import clsx from 'clsx'

import styles from './title.module.scss'

export interface TitleProps {
    children: React.ReactNode
    variant: 'h1' | 'h2' | 'h3' | 'legend'
}

export const Title: FC<TitleProps> = ({ children, variant = 'h1' }) => {
    const Component = variant

    return (
        <Component
            className={clsx({
                [styles['title']]: true,
                [styles[`title--variant-${variant}`]]: true,
            })}
        >
            {children}
        </Component>
    )
}

Title.displayName = 'Title'
