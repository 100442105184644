import React, { useEffect } from 'react'
import { getCompaniesCompanyIdAddressesSearch } from '@dostavkee/contracts-console/api'
import { AddressModel } from '@dostavkee/contracts-console/entities'
import { useDeviceDetection } from '@dostavkee/react-hooks'
import { Autocomplete, Button, CalendarRange, ChipArray, MultiSelect } from '@dostavkee/web-ui'
import { useNavigate, useRouterState, useSearch } from '@tanstack/react-router'
import dayjs from 'dayjs'
import isEqual from 'lodash.isequal'
import { Controller, useForm } from 'react-hook-form'

import { useAuth } from '@/shell/shared/model'

import { GetOrdersSearchStage, STAGE_SELECT_OPTIONS } from '../model/constants/stage-select-options'
import { OrdersSearchDownloadReports } from './orders-search-download-reports'
import styles from './orders-search-form.module.scss'

const getInjectedBottomSheet = () => document.querySelector('#bottom-sheet') as HTMLElement

interface SearchFormValues {
    address: {
        id?: string
        name?: string
    }
    date_range: {
        from?: Date
        to?: Date
    }
    stages?: GetOrdersSearchStage[]
}

interface OrdersSearchFormProps {
    onClose?: () => void
    onStartOpenSearch?: () => void
    onStartCloseSearch?: () => void
}

const isEmptyValues = (values: SearchFormValues) => {
    return (
        !values.address?.id &&
        !values.date_range?.from &&
        !values.date_range?.to &&
        !values.stages?.length
    )
}

export const OrdersSearchForm: React.FC<OrdersSearchFormProps> = ({
    onClose,
    onStartOpenSearch,
    onStartCloseSearch,
}) => {
    const pathname = useRouterState({
        select: (state) => state.location.pathname,
    })
    const isSearchPage = pathname === '/dostavkee/orders/search'

    const searchValues = useSearch({
        from: '/_dashboard/dostavkee/_sidebar-layout/orders/_tabs',
        select: (data) => ({
            address: {
                id: data?.address_id,
                name: data?.address_name,
            },
            date_range: {
                from: data?.start_date ? dayjs(data.start_date, 'DD-MM-YYYY').toDate() : undefined,
                to: data?.end_date ? dayjs(data.end_date, 'DD-MM-YYYY').toDate() : undefined,
            },
            stages: data?.stages,
        }),
    })

    const { isMobile } = useDeviceDetection()

    const { control, handleSubmit, getValues, setValue, watch } = useForm<SearchFormValues>({
        defaultValues: searchValues,
    })

    useEffect(() => {
        const values = getValues()
        if (values && !isEqual(values, searchValues) && !isMobile) {
            setValue('address', searchValues?.address ?? undefined)
            setValue('date_range', searchValues?.date_range ?? undefined)
            setValue('stages', searchValues?.stages ?? undefined)
        }
    }, [getValues, isMobile, searchValues, setValue])

    const { companyId } = useAuth()

    const navigate = useNavigate()

    const handleSearchSubmit = React.useCallback(
        (values: SearchFormValues) => {
            if (isEmptyValues(values)) {
                navigate({
                    to: '/dostavkee/orders/active',
                    search: {
                        page: 1,
                        stages: [],
                    },
                })
            } else {
                navigate({
                    to: '/dostavkee/orders/search',
                    search: {
                        page: 1,
                        address_id: values.address?.id,
                        address_name: values.address?.name,
                        start_date:
                            values.date_range &&
                            values.date_range.from &&
                            dayjs(values.date_range.from).format('DD-MM-YYYY'),
                        end_date:
                            values.date_range &&
                            values.date_range.to &&
                            dayjs(values.date_range.to).format('DD-MM-YYYY'),
                        stages:
                            values.stages && values.stages.length === 0 ? undefined : values.stages,
                    },
                })
            }

            if (typeof onClose === 'function') {
                onClose()
            }
        },
        [navigate, onClose]
    )

    useEffect(() => {
        if (isMobile) {
            return
        }

        const subscription = watch((values) => {
            if (isSearchPage && isEmptyValues(values as SearchFormValues)) {
                return navigate({
                    to: '/dostavkee/orders/active',
                    search: {
                        page: 1,
                        stages: [],
                    },
                })
            }

            if (isEqual(values, searchValues)) {
                return
            }

            handleSearchSubmit(values as SearchFormValues)
        })

        return () => subscription.unsubscribe()
    }, [watch, handleSearchSubmit, searchValues, isMobile, navigate, isSearchPage])

    const handleReset = React.useCallback(async () => {
        navigate({
            to: isSearchPage ? '/dostavkee/orders/active' : pathname,
            search: {
                page: 1,
                stages: [],
                address_id: undefined,
                address_name: undefined,
                start_date: undefined,
                end_date: undefined,
            },
        })

        if (isMobile) {
            setValue('address', {
                id: undefined,
                name: undefined,
            })
            setValue('date_range', {
                from: undefined,
                to: undefined,
            })
            setValue('stages', [])
        }
    }, [navigate, isSearchPage, pathname, isMobile, setValue])

    return (
        <form
            className={styles['orders-search-form']}
            onReset={handleReset}
            onSubmit={handleSubmit(handleSearchSubmit)}
        >
            <Controller
                control={control}
                name='address'
                render={({ field, fieldState }) => (
                    <div className={styles['orders-search-form__name']}>
                        <Autocomplete<AddressModel>
                            {...field}
                            isSelectedRequired
                            autoComplete='off'
                            errorMessage={fieldState.error?.message}
                            id='id'
                            isInvalid={fieldState.invalid}
                            label='Название или адрес организации'
                            mode={isMobile ? 'absolute' : 'portaled'}
                            size='small'
                            type='text'
                            value={field.value?.name}
                            loadOptions={async (value) => {
                                const response = await getCompaniesCompanyIdAddressesSearch(
                                    companyId as string,
                                    {
                                        name: value,
                                    }
                                )

                                if (!response.addresses) {
                                    return []
                                }

                                return response.addresses.map((address) => ({
                                    id: address.id,
                                    name: address.name,
                                    hint: address.address,
                                }))
                            }}
                            noResult={
                                <div
                                    className={styles['orders-search-form__autocomplete-no-result']}
                                >
                                    Ничего не найдено
                                </div>
                            }
                            onStartClose={onStartCloseSearch}
                            onStartOpen={onStartOpenSearch}
                            onSelect={(option) => {
                                if (option?.id) {
                                    field.onChange(option)
                                } else {
                                    field.onChange('')
                                }
                            }}
                        />
                    </div>
                )}
            />
            <Controller
                control={control}
                name='date_range'
                render={({ field, fieldState }) => (
                    <div className={styles['orders-search-form__date-range']}>
                        <CalendarRange
                            {...field}
                            errorMessage={fieldState.error?.message}
                            getInjectedEl={getInjectedBottomSheet}
                            isInvalid={fieldState.invalid}
                            label='Период'
                            size='small'
                            value={{
                                from: field.value?.from,
                                to: field.value?.to,
                            }}
                            onChange={(value) => {
                                if (value) {
                                    field.onChange(value)
                                } else {
                                    field.onChange({
                                        from: undefined,
                                        to: undefined,
                                    })
                                }
                            }}
                        />
                    </div>
                )}
            />

            <Controller
                control={control}
                name='stages'
                render={({ field, fieldState }) => {
                    if (isMobile) {
                        return (
                            <div>
                                <div className={styles['orders-search-form__title']}>
                                    Статусы заказа
                                </div>

                                <ChipArray
                                    {...field}
                                    withSelectAll
                                    options={STAGE_SELECT_OPTIONS}
                                />
                            </div>
                        )
                    }

                    return (
                        <div className={styles['orders-search-form__stage']}>
                            <MultiSelect
                                {...field}
                                errorMessage={fieldState.error?.message}
                                isInvalid={fieldState.invalid}
                                label='Статус'
                                options={STAGE_SELECT_OPTIONS}
                                size='small'
                                value={STAGE_SELECT_OPTIONS.filter((item) => {
                                    const array: string[] = getValues('stages') as string[]
                                    if (array) {
                                        return array.includes(item.id.toString())
                                    }

                                    return false
                                })}
                                onChange={(options) => {
                                    field.onChange(
                                        options.map(
                                            (item) => item.id.toString() as GetOrdersSearchStage
                                        )
                                    )
                                }}
                            />
                        </div>
                    )
                }}
            />

            <div className={styles['orders-search-form__controls']}>
                {isMobile && (
                    <>
                        <Button fullWidth={isMobile} size='medium' type='reset' variant='secondary'>
                            Сбросить
                        </Button>
                        <Button fullWidth={isMobile} size='medium' type='submit'>
                            Найти
                        </Button>
                    </>
                )}
                {!isMobile && (
                    <OrdersSearchDownloadReports
                        address_id={searchValues.address?.id}
                        end_date={
                            searchValues.date_range.to
                                ? dayjs(searchValues.date_range.to).format('DD-MM-YYYY')
                                : undefined
                        }
                        isDisabled={
                            !(
                                searchValues.stages?.length === 1 &&
                                searchValues.stages?.includes(GetOrdersSearchStage.orderCompleted)
                            )
                        }
                        start_date={
                            searchValues.date_range.from
                                ? dayjs(searchValues.date_range.from).format('DD-MM-YYYY')
                                : undefined
                        }
                    />
                )}
            </div>
        </form>
    )
}
