/* eslint-disable no-self-compare */

// stolen from lodash
export function clamp(number: number, lower: number, upper: number) {
    number = +number
    lower = +lower
    upper = +upper
    lower = lower === lower ? lower : 0
    upper = upper === upper ? upper : 0
    if (number === number) {
        number = number <= upper ? number : upper
        number = number >= lower ? number : lower
    }

    return number
}

// Mwahaha easiest way to filter out NaN I ever saw! >:3
export function deleteNaN(array: any[]) {
    const set = new Set(array)
    set.delete(Number.NaN)

    return [...set]
}

export function roundAndCheckForNaN(unrounded: any) {
    if (Number.isNaN(unrounded)) {
        throw new TypeError('Found a NaN! Check your snapPoints / defaultSnap / snapTo ')
    }

    const rounded = Math.round(unrounded)

    return rounded
}

// Validate, sanitize, round and dedupe snap points, as well as extracting the minSnap and maxSnap points
export function processSnapPoints(unsafeSnaps: number | number[], maxHeight: number) {
    const safeSnaps = [unsafeSnaps].flat().map((element) => roundAndCheckForNaN(element))

    // eslint-disable-next-line unicorn/no-array-reduce
    const snapPointsDedupedSet = safeSnaps.reduce((accumulator, snapPoint) => {
        accumulator.add(clamp(snapPoint, 0, maxHeight))

        return accumulator
    }, new Set<number>())

    const snapPoints = [...snapPointsDedupedSet]

    const minSnap = Math.min(...snapPoints)
    if (Number.isNaN(minSnap)) {
        throw new TypeError('minSnap is NaN')
    }
    const maxSnap = Math.max(...snapPoints)
    if (Number.isNaN(maxSnap)) {
        throw new TypeError('maxSnap is NaN')
    }

    return {
        snapPoints,
        minSnap,
        maxSnap,
    }
}
