import React from 'react'

import styles from './bottom-sheet.module.scss'

interface BottomSheetContainerProps {
    children: React.ReactNode
}

export const BottomSheetContainer: React.FC<BottomSheetContainerProps> = ({ children }) => {
    return (
        <div className={styles['bottom-sheet__container']} id='bottom-sheet__container'>
            {children}
        </div>
    )
}
