import React, { FC, forwardRef, useRef, useState } from 'react'
import { useDeviceDetection, useOnClickOutside } from '@dostavkee/react-hooks'
import dayjs from 'dayjs'
import { DateRange, DayPicker } from 'react-day-picker'
import { ru } from 'react-day-picker/locale'

import './calendar.css'

import { Button } from '../button'
import { Flex } from '../flex'
import { IconCalendar, IconChevronLeft } from '../icon'
import { Input, InputProps } from '../input'
import { Modal } from '../modal'
import styles from './calendar-range.module.scss'

const getFormattedValue = (value?: DateRange) => {
    if (value?.from && value?.to) {
        return dayjs(value.from).isSame(value.to, 'day')
            ? dayjs(value.to).format('DD.MM.YYYY')
            : `${dayjs(value.from).format('DD.MM.YYYY')}-${dayjs(value.to).format('DD.MM.YYYY')}`
    }
}

export interface CalendarRangeProps extends Omit<InputProps, 'onChange' | 'value'> {
    id?: string
    onChange: (value?: DateRange) => void
    value?: DateRange
    getInjectedEl?: () => HTMLElement
}

const TODAY_DATE = new Date()

export const CalendarRange: FC<CalendarRangeProps> = forwardRef<
    HTMLInputElement,
    CalendarRangeProps
>(
    (
        {
            placeholder,
            onChange,
            value,
            isInvalid,
            errorMessage,
            label,
            id,
            disabled,
            getInjectedEl,
            ...props
        },
        ref
    ) => {
        const [isOpen, setIsOpen] = useState(false)

        const dropdownRef = useRef<HTMLDivElement>(null)

        const [month, setMonth] = useState<Date>(value?.from || TODAY_DATE)

        const { isMobile } = useDeviceDetection()

        const [selected, setSelected] = useState<DateRange | undefined>(value)

        const handleClose = React.useCallback(() => {
            setIsOpen(false)
        }, [])

        useOnClickOutside(dropdownRef, handleClose)

        const handleReset = React.useCallback(() => {
            setSelected(undefined)
            setMonth(TODAY_DATE)
            onChange()
        }, [onChange])

        const handleApply = React.useCallback(() => {
            onChange(selected)
            handleClose()
        }, [onChange, handleClose, selected])

        const renderDayPicker = React.useCallback(
            (numberOfMonths: number) => {
                return (
                    <DayPicker
                        locale={ru}
                        mode='range'
                        numberOfMonths={numberOfMonths}
                        selected={selected}
                        showOutsideDays={false}
                        components={{
                            Chevron: () => <IconChevronLeft size={20} />,
                        }}
                        disabled={{
                            after: TODAY_DATE,
                        }}
                        month={
                            numberOfMonths === 1
                                ? month
                                : dayjs(month)
                                      .subtract(numberOfMonths - 1, 'month')
                                      .toDate()
                        }
                        onSelect={setSelected}
                        onNextClick={() => {
                            setMonth(dayjs(month).add(1, 'month').toDate())
                        }}
                        onPrevClick={() => {
                            setMonth(dayjs(month).subtract(1, 'month').toDate())
                        }}
                    />
                )
            },
            [month, selected]
        )

        const renderInputs = React.useCallback(() => {
            return (
                <Flex grow={1} spacing='m' wrap='nowrap'>
                    <Input
                        label='От'
                        type='button'
                        value={selected?.from && dayjs(selected.from).format('DD.MM.YYYY')}
                        onClick={() => {
                            setMonth(selected?.from || TODAY_DATE)
                        }}
                    />
                    <Input
                        label='До'
                        type='button'
                        value={selected?.to && dayjs(selected.to).format('DD.MM.YYYY')}
                        onClick={() => {
                            setMonth(selected?.to || TODAY_DATE)
                        }}
                    />
                </Flex>
            )
        }, [selected])

        const renderButtons = React.useCallback(() => {
            return (
                <Flex justify='flex-end' spacing='xs'>
                    <Button
                        size='small'
                        variant='secondary'
                        onClick={() => {
                            handleReset()
                        }}
                    >
                        Сбросить
                    </Button>
                    <Button
                        size='small'
                        variant='primary'
                        onClick={() => {
                            handleApply()
                        }}
                    >
                        Применить
                    </Button>
                </Flex>
            )
        }, [handleApply, handleReset])

        return (
            <div className={styles['calendar']}>
                <Input
                    {...props}
                    ref={ref}
                    disabled={disabled}
                    errorMessage={errorMessage}
                    id={id}
                    isInvalid={isInvalid}
                    label={label}
                    placeholder={placeholder}
                    postfixEl={<IconCalendar color='var(--text-and-icon-secondary)' size={22} />}
                    type='button'
                    value={getFormattedValue(value)}
                    onClick={() => {
                        setIsOpen(true)
                    }}
                />
                {isOpen && (
                    <>
                        {isMobile ? (
                            <Modal
                                isOpen
                                inject={getInjectedEl && getInjectedEl()}
                                size='small'
                                onClose={() => {
                                    handleClose()
                                }}
                            >
                                <Modal.Body isFitContent>
                                    <div className={styles['calendar__wrapper']}>
                                        <div className={styles['calendar__inputs']}>
                                            {renderInputs()}
                                        </div>
                                        <div className={styles['calendar__picker']}>
                                            {renderDayPicker(1)}
                                        </div>
                                        {renderButtons()}
                                    </div>
                                </Modal.Body>
                            </Modal>
                        ) : (
                            <div ref={dropdownRef} className={styles['calendar__dropdown']}>
                                <Flex direction='column' spacing='xl'>
                                    {renderInputs()}
                                    {renderDayPicker(2)}
                                    {renderButtons()}
                                </Flex>
                            </div>
                        )}
                    </>
                )}
            </div>
        )
    }
)

CalendarRange.displayName = 'Calendar'
