import client from '../../axios-client'
import type { RequestConfig, ResponseErrorConfig } from '../../axios-client'
import type { GetOrdersQueryResponse, GetOrdersQueryParams, GetOrders400 } from '../entities/GetOrders'
import type { QueryKey, QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import { queryOptions, useQuery } from '@tanstack/react-query'

export const getOrdersQueryKey = (params: GetOrdersQueryParams) => [{ url: '/orders' }, ...(params ? [params] : [])] as const

export type GetOrdersQueryKey = ReturnType<typeof getOrdersQueryKey>

/**
 * @description Получение списка заказов
 * @summary Получение списка заказов
 * {@link /orders}
 */
async function getOrders(params: GetOrdersQueryParams, config: Partial<RequestConfig> = {}) {
  const res = await client<GetOrdersQueryResponse, ResponseErrorConfig<GetOrders400>, unknown>({ method: 'GET', url: `/orders`, params, ...config })
  return res.data
}

export function getOrdersQueryOptions(params: GetOrdersQueryParams, config: Partial<RequestConfig> = {}) {
  const queryKey = getOrdersQueryKey(params)
  return queryOptions<GetOrdersQueryResponse, ResponseErrorConfig<GetOrders400>, GetOrdersQueryResponse, typeof queryKey>({
    enabled: !!params,
    queryKey,
    queryFn: async ({ signal }) => {
      config.signal = signal
      return getOrders(params, config)
    },
  })
}

/**
 * @description Получение списка заказов
 * @summary Получение списка заказов
 * {@link /orders}
 */
export function useGetOrders<TData = GetOrdersQueryResponse, TQueryData = GetOrdersQueryResponse, TQueryKey extends QueryKey = GetOrdersQueryKey>(
  params: GetOrdersQueryParams,
  options: {
    query?: Partial<QueryObserverOptions<GetOrdersQueryResponse, ResponseErrorConfig<GetOrders400>, TData, TQueryData, TQueryKey>>
    client?: Partial<RequestConfig>
  } = {},
) {
  const { query: queryOptions, client: config = {} } = options ?? {}
  const queryKey = queryOptions?.queryKey ?? getOrdersQueryKey(params)

  const query = useQuery({
    ...(getOrdersQueryOptions(params, config) as unknown as QueryObserverOptions),
    queryKey,
    ...(queryOptions as unknown as Omit<QueryObserverOptions, 'queryKey'>),
  }) as UseQueryResult<TData, ResponseErrorConfig<GetOrders400>> & { queryKey: TQueryKey }

  query.queryKey = queryKey as TQueryKey

  return query
}