import React, { CSSProperties } from 'react'

import { FlexItem } from './flex-item'

type SpacingType = '0' | '2xs' | 'xs' | 's' | 'm' | 'l' | 'xl'

const styles = window.getComputedStyle(document.documentElement)

export interface FlexComposition {
    Item: typeof FlexItem
}

export interface FlexProps {
    children: React.ReactNode
    columns?: number
    columnsSpacing?: SpacingType
    rowSpacing?: SpacingType
    spacing?: SpacingType
    direction?: CSSProperties['flexDirection']
    flex?: CSSProperties['flex']
    wrap?: CSSProperties['flexWrap']
    justify?: CSSProperties['justifyContent']
    align?: CSSProperties['alignItems']
    grow?: CSSProperties['flexGrow']
    style?: CSSProperties
    fullWidth?: boolean
}

export const Flex: React.FC<FlexProps> & FlexComposition = ({
    children,
    columns = 12,
    rowSpacing,
    spacing = '0',
    direction = 'row',
    wrap = 'wrap',
    justify,
    align,
    flex,
    grow,
    fullWidth = false,
    style,
}: FlexProps) => {
    if (columns && (columns < 1 || columns > 12)) {
        throw new Error('Columns should be between 1 and 12')
    }

    const spacer = styles.getPropertyValue(`--spacers-${spacing}`)
    const rowSpacer = styles.getPropertyValue(`--spacers-${rowSpacing}`)

    return (
        <div
            style={{
                display: 'flex',
                flexWrap: wrap,
                flexDirection: direction,
                justifyContent: justify,
                alignItems: align,
                gap: `${spacer} ${rowSpacer}`,
                flex,
                flexGrow: grow,
                width: fullWidth ? '100%' : undefined,
                ...style,
            }}
        >
            {React.Children.map(children, (child) =>
                child
                    ? React.cloneElement(child as React.ReactElement<any>, {
                          _spacing: spacing,
                          _columns: columns,
                      })
                    : null
            )}
        </div>
    )
}

Flex.displayName = 'Flex'

Flex.Item = FlexItem
