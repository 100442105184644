import React, { FC } from 'react'
import { useDeviceDetection } from '@dostavkee/react-hooks'
import { Button, IconDownload } from '@dostavkee/web-ui'
import { Tooltip } from 'react-tooltip'

import { useOrdersDownloadReport } from '@/dostavkee/entities/orders'

import styles from './orders-search-download-reports.module.scss'

interface OrdersSearchDownloadReportsProps {
    address_id?: string
    start_date?: string
    end_date?: string
    isDisabled?: boolean
}

export const OrdersSearchDownloadReports: FC<OrdersSearchDownloadReportsProps> = ({
    address_id,
    start_date,
    end_date,
    isDisabled,
}) => {
    const { downloadReport, isPending } = useOrdersDownloadReport()
    const { isMobile } = useDeviceDetection()

    return (
        <>
            <div
                data-tooltip-content='Скачать отчёт можно только по доставленным заказам'
                data-tooltip-id='download-report'
                data-tooltip-place={isMobile ? 'bottom' : 'top-start'}
                style={isMobile ? { padding: '0 16px', margin: '16px 0', textAlign: 'center' } : {}}
            >
                <Button
                    disabled={isMobile ? isPending : isPending || isDisabled}
                    fullWidth={isMobile}
                    prefixEl={<IconDownload color={isMobile ? 'black' : 'white'} />}
                    size='medium'
                    variant={isMobile ? 'secondary' : 'primary'}
                    onClick={() => {
                        if (isDisabled) {
                            return
                        }
                        downloadReport(start_date, end_date, address_id)
                    }}
                >
                    Скачать отчёт
                </Button>
            </div>
            {isDisabled && (
                <Tooltip
                    className={styles['tooltip-download-report']}
                    delayHide={0}
                    delayShow={0}
                    id='download-report'
                    place='top-start'
                />
            )}
        </>
    )
}
