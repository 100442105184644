import React from 'react'

// Handle hiding and restoring aria-hidden attributes
export function useAriaHider({
    targetRef,
    enabled,
}: {
    targetRef: React.RefObject<Element>
    enabled: boolean
}) {
    const ref = React.useRef<{ activate: () => void; deactivate: () => void }>({
        activate: () => {
            throw new TypeError('Tried to activate aria hider too early')
        },
        deactivate: () => {},
    })

    React.useDebugValue(enabled ? 'Enabled' : 'Disabled')

    React.useEffect(() => {
        if (!enabled) {
            ref.current.deactivate()
            ref.current = { activate: () => {}, deactivate: () => {} }

            return
        }

        const target = targetRef.current
        let active = false
        let originalValues: (null | string)[] = []
        let rootNodes: Element[] = []

        ref.current = {
            activate: () => {
                if (active) return
                active = true

                if (target) {
                    const parentNode = target.parentNode

                    // eslint-disable-next-line unicorn/no-array-for-each
                    document.querySelectorAll('body > *').forEach((node) => {
                        if (node === parentNode) {
                            return
                        }
                        let attribute = node.getAttribute('aria-hidden')
                        let alreadyHidden = attribute !== null && attribute !== 'false'
                        if (alreadyHidden) {
                            return
                        }
                        originalValues.push(attribute)
                        rootNodes.push(node)
                        node.setAttribute('aria-hidden', 'true')
                    })
                }
            },
            deactivate: () => {
                if (!active) return
                active = false

                for (const [index, node] of rootNodes.entries()) {
                    let originalValue = originalValues[index]
                    if (originalValue === null) {
                        node.removeAttribute('aria-hidden')
                    } else {
                        node.setAttribute('aria-hidden', originalValue)
                    }
                }
                originalValues = []
                rootNodes = []
            },
        }
    }, [targetRef, enabled])

    return ref
}
