import { useDebugValue, useEffect, useMemo, useRef } from 'react'

// @TODO refactor to addEventListener
export function useReducedMotion() {
    const mql = useMemo(
        () =>
            typeof window === 'undefined'
                ? null
                : window.matchMedia('(prefers-reduced-motion: reduce)'),
        []
    )
    const ref = useRef(mql?.matches)

    useDebugValue(ref.current ? 'reduce' : 'no-preference')

    useEffect(() => {
        const handler = (event: MediaQueryListEvent) => {
            ref.current = event.matches
        }

        mql?.addEventListener('change', handler)

        return () => mql?.removeEventListener('change', handler)
    }, [mql])

    return ref
}
