import React from 'react'
import clsx from 'clsx'

import { Flex } from '../flex'
import { IconClose } from '../icon'
import { SelectOption } from '../select'
import styles from './chip-array.module.scss'

export interface ChipArrayProps
    extends Omit<React.HTMLAttributes<HTMLDivElement>, 'value' | 'onChange'> {
    options: SelectOption[]
    value?: string[]
    onChange: (value: string[]) => void
    prefixEl?: React.ReactNode
    withSelectAll?: boolean
}

export const ChipArray: React.FC<ChipArrayProps> = React.forwardRef<HTMLDivElement, ChipArrayProps>(
    ({ options, value = [], onChange, prefixEl, withSelectAll = false, ...props }, ref) => {
        const handleOptionChange = React.useCallback(
            (option: SelectOption, isSelected: boolean, value: string[] = []) => {
                const optionValue = option.id.toString()
                const updatedValue = isSelected
                    ? value.filter((item) => item !== optionValue)
                    : [...value, optionValue]
                onChange(updatedValue)
            },
            [onChange]
        )

        return (
            <div ref={ref}>
                <Flex spacing='xs' wrap='wrap'>
                    {withSelectAll && (
                        <div
                            role='checkbox'
                            className={clsx({
                                [styles['chip']]: true,
                                [styles['chip_selected']]: value.length === options.length,
                            })}
                            onClick={(event) => {
                                event.preventDefault()
                                event.stopPropagation()
                                if (value.length === options.length) {
                                    onChange([])
                                } else {
                                    onChange(options.map((item) => item.id as string))
                                }
                            }}
                            {...props}
                        >
                            {prefixEl && <div>{prefixEl}</div>}
                            <div className={styles['chip__content']}>Все</div>
                            {value.length === options.length && (
                                <IconClose color={'var(--text-and-icon-on-color)'} size={20} />
                            )}
                        </div>
                    )}
                    {options.map((item) => {
                        const isSelected = value.includes(item.id as string)

                        return (
                            <div
                                key={item.id}
                                role='checkbox'
                                className={clsx({
                                    [styles['chip']]: true,
                                    [styles['chip_selected']]: isSelected,
                                })}
                                onClick={(event) => {
                                    event.preventDefault()
                                    event.stopPropagation()
                                    handleOptionChange(item, isSelected, value)
                                }}
                                {...props}
                            >
                                {prefixEl && <div>{prefixEl}</div>}
                                <div className={styles['chip__content']}>{item.name}</div>
                                {isSelected && (
                                    <IconClose color={'var(--text-and-icon-on-color)'} size={20} />
                                )}
                            </div>
                        )
                    })}
                </Flex>
            </div>
        )
    }
)

ChipArray.displayName = 'ChipArray'
