import React from 'react'
import { GetOrdersQueryParamsType } from '@dostavkee/contracts-console/entities'
import { useDeviceDetection } from '@dostavkee/react-hooks'
import { Title } from '@dostavkee/web-ui'
import { createFileRoute, Outlet, useNavigate, useRouterState } from '@tanstack/react-router'
import clsx from 'clsx'
import { useSwipeable } from 'react-swipeable'

import {
    OrderAlerts,
    OrdersFiltersBottomSheet,
    useOpenOrdersFilter,
    useOrdersFetch,
} from '@/dostavkee/entities/orders'
import { GetOrdersSearchStage, OrdersSearchForm } from '@/dostavkee/pages/orders-search-page'

import styles from './_tabs.module.scss'

export const Route = createFileRoute('/_dashboard/dostavkee/_sidebar-layout/orders/_tabs')({
    component: OrdersPage,
    validateSearch: (
        search: Record<string, unknown>
    ): {
        address_id?: string
        address_name?: string
        page?: number
        start_date?: string
        end_date?: string
        stages?: GetOrdersSearchStage[]
    } => {
        return {
            page: (search.page as number) ?? 1,
            stages: search.stages as GetOrdersSearchStage[],
            start_date: search.start_date as string,
            end_date: search.end_date as string,
            address_id: search.address_id as string,
            address_name: search.address_name as string,
        }
    },
})

function OrdersPage() {
    const navigate = useNavigate()
    const pathname = useRouterState({ select: (state) => state.location.pathname })
    const { isDesktop, isMobile } = useDeviceDetection()
    const { isOpen, setIsOpen } = useOpenOrdersFilter()

    const { total } = useOrdersFetch({
        params: {
            type: GetOrdersQueryParamsType.active,
        },
        query: {
            refetchOnWindowFocus: false,
        },
    })

    const swipeHandlers = useSwipeable({
        onSwipedRight: () => {
            if (pathname !== '/dostavkee/orders/active') {
                navigate({ to: '/dostavkee/orders/active' })
            }
        },
        onSwipedLeft: () => {
            if (pathname !== '/dostavkee/orders/archive') {
                navigate({ to: '/dostavkee/orders/archive' })
            }
        },
        delta: 120,
        trackTouch: true,
        trackMouse: false,
    })

    const isSearchPage = pathname === '/dostavkee/orders/search'

    return (
        <div
            className={styles['orders-page']}
            style={{ paddingTop: !isMobile || isSearchPage ? 0 : 45 }}
        >
            <Title variant='h1'>Заказы</Title>

            <OrderAlerts />

            {isDesktop && <OrdersSearchForm />}

            {!isSearchPage && (
                <div className={styles['tabs']}>
                    <button
                        className={clsx(styles['tabs__tab-button'], {
                            [styles['tabs__tab-button--active']]:
                                pathname === '/dostavkee/orders/active',
                        })}
                        onClick={() => navigate({ to: '/dostavkee/orders/active' })}
                    >
                        <span>Активные</span>
                        {Boolean(total && total > 0) && (
                            <span className={styles['badge']}>{total}</span>
                        )}
                    </button>
                    <button
                        className={clsx(styles['tabs__tab-button'], {
                            [styles['tabs__tab-button--active']]:
                                pathname === '/dostavkee/orders/archive',
                        })}
                        onClick={() => navigate({ to: '/dostavkee/orders/archive' })}
                    >
                        <span>Архив</span>
                    </button>
                </div>
            )}
            <div {...swipeHandlers}>
                <Outlet />
            </div>

            {isMobile && (
                <OrdersFiltersBottomSheet isOpen={isOpen} onClose={() => setIsOpen(false)} />
            )}
        </div>
    )
}
