import React, { FC } from 'react'

import styles from './bottom-sheet.module.scss'

interface BottomSheetFooterProps {
    children: React.ReactNode
}

export const BottomSheetFooter: FC<BottomSheetFooterProps> = ({ children }) => {
    return <footer className={styles['bottom-sheet__footer']}>{children}</footer>
}
