import React from 'react'

export interface FlexItemProps {
    children: React.ReactNode
    readonly _spacing?: string
    readonly _columns?: number
    md?: number
    sm?: number
    xs?: number
}

export const FlexItem: React.FC<FlexItemProps> = ({
    children,
    _spacing,
    _columns = 12,
    md,
    sm,
    xs,
}) => {
    if ((md && (md < 1 || md > 12)) || (sm && (sm < 1 || sm > 12)) || (xs && (xs < 1 || xs > 12))) {
        throw new Error('Columns should be between 1 and 12')
    }

    const style = {
        ...(xs ? { flexBasis: `${(xs / _columns) * 100}%`, padding: _spacing } : {}),
        ...(md ? { flexBasis: `${(md / _columns) * 100}%`, padding: _spacing } : {}),
        ...(sm ? { flexBasis: `${(sm / _columns) * 100}%`, padding: _spacing } : {}),
    }

    return <div style={style}>{children}</div>
}
