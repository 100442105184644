import { GetOrdersSearchQueryParamsStages } from '@dostavkee/contracts-console/entities'

export enum GetOrdersSearchStage {
    searching = GetOrdersSearchQueryParamsStages.searching,
    courierAccepted = GetOrdersSearchQueryParamsStages.courierAccepted,
    courierArrived = GetOrdersSearchQueryParamsStages.courierArrived,
    courierPickedUp = GetOrdersSearchQueryParamsStages.courierPickedUp,
    orderCompleted = GetOrdersSearchQueryParamsStages.orderCompleted,
    canceledByTimeout = GetOrdersSearchQueryParamsStages.canceledByTimeout,

    /**
     * Кастомный клиентский статус отмены, в нее входят:
     * – GetOrdersSearchQueryParamsStages.courierCanceled
     * – GetOrdersSearchQueryParamsStages.clientCanceled
     * – GetOrdersSearchQueryParamsStages.clientArchived
     */
    cancel = 'GetOrdersSearchCancelStage',
}

export const STAGE_SELECT_OPTIONS = [
    {
        id: GetOrdersSearchStage.searching,
        name: 'Поиск курьера',
    },
    {
        id: GetOrdersSearchStage.courierAccepted,
        name: 'Курьер в пути',
    },
    {
        id: GetOrdersSearchStage.courierArrived,
        name: 'Курьер на месте',
    },
    {
        id: GetOrdersSearchStage.courierPickedUp,
        name: 'Курьер забрал заказ',
    },
    {
        id: GetOrdersSearchStage.orderCompleted,
        name: 'Доставлен',
    },
    {
        id: GetOrdersSearchStage.canceledByTimeout,
        name: 'Курьер не найден',
    },
    {
        id: GetOrdersSearchStage.cancel,
        name: 'Отменён',
    },
]
