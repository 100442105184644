import React, { FC, forwardRef, ReactNode, useRef, useState } from 'react'
import { useOnClickOutside } from '@dostavkee/react-hooks'
import clsx from 'clsx'
import { createPortal } from 'react-dom'

import { Checkbox } from '../checkbox'
import { Input, InputProps } from '../input'
import { SelectOption } from '../select'
import { Spinner } from '../spinner'
import styles from './multi-select.module.scss'

export interface MultiSelectProps extends Omit<InputProps, 'onChange' | 'value'> {
    options: SelectOption[]
    value?: SelectOption[]
    footer?: (isOpen: boolean, setIsOpen: (isOpen: boolean) => void) => ReactNode
    id?: string
    onChange: (value: SelectOption[]) => void
    isLoading?: boolean
    mode?: 'absolute' | 'portal'
}

export const MultiSelect: FC<MultiSelectProps> = forwardRef<HTMLDivElement, MultiSelectProps>(
    (
        {
            value = [],
            placeholder,
            options,
            onChange,
            isInvalid,
            errorMessage,
            label,
            footer,
            id,
            isLoading,
            disabled,
            mode = 'absolute',
            ...props
        },
        ref
    ) => {
        const [isOpen, setIsOpen] = useState(false)

        const contentRef = useRef<HTMLDivElement>(null)
        const listRef = useRef<HTMLDivElement>(null)

        useOnClickOutside(listRef, () => {
            if (isOpen) {
                setIsOpen(false)
            }
        })

        const getDisplayValue = () => {
            if (value.length === 1) return value[0].name
            if (value.length === options.length) return 'Все'
            if (value.length > 1) return `Выбрано: ${value.length}`

            return ''
        }

        const handleSelect = React.useCallback(
            (option: SelectOption) => {
                const isSelected = value.some((item) => item.id === option.id)

                if (isSelected) {
                    onChange(value.filter((item) => item.id !== option.id))
                } else {
                    onChange([...value, option])
                }
            },
            [onChange, value]
        )

        const renderContent = () => {
            if (!contentRef.current) {
                return null
            }

            const { top, left, width, height } = contentRef.current.getBoundingClientRect()

            return (
                <div
                    ref={listRef}
                    className={styles['multi-select-dropdown']}
                    style={
                        mode === 'portal'
                            ? { top: top + height + 8, left, width }
                            : {
                                  top: `calc(100% + 8px)`,
                                  width: '100%',
                              }
                    }
                >
                    <div className={styles['multi-select-dropdown__container']}>
                        <Checkbox
                            checkboxSize='small'
                            checked={value.length === options.length}
                            isIndeterminate={value.length > 0 && value.length < options.length}
                            onChange={() => {
                                if (value.length === options.length) {
                                    onChange([])
                                } else {
                                    onChange(options)
                                }
                            }}
                        >
                            Все
                        </Checkbox>

                        <div className={styles['multi-select-dropdown__options']}>
                            {!isLoading &&
                                options?.map((option) => {
                                    const isSelected = value.some((item) => item.id === option.id)

                                    return (
                                        <div
                                            key={option.id}
                                            className={
                                                styles['multi-select-dropdown__options__item']
                                            }
                                        >
                                            <Checkbox
                                                checkboxSize='small'
                                                checked={isSelected}
                                                onChange={() => handleSelect(option)}
                                            >
                                                {option.name}
                                            </Checkbox>
                                            {option.hint && (
                                                <div
                                                    className={
                                                        styles[
                                                            'multi-select-dropdown__options__item-hint'
                                                        ]
                                                    }
                                                >
                                                    {option.hint}
                                                </div>
                                            )}
                                        </div>
                                    )
                                })}
                        </div>
                        {!isLoading && !options && (
                            <div
                                className={clsx({
                                    [styles['multi-select-dropdown__options__item-empty']]: true,
                                    [styles['multi-select-dropdown__options__item']]: true,
                                })}
                            >
                                Нет данных
                            </div>
                        )}

                        {typeof footer === 'function' && footer(isOpen, setIsOpen)}
                    </div>
                </div>
            )
        }

        return (
            <div ref={ref} className={styles['multi-select-wrapper']}>
                <div ref={contentRef}>
                    <Input
                        {...props}
                        disabled={disabled || isLoading}
                        errorMessage={errorMessage}
                        id={id}
                        isInvalid={isInvalid}
                        label={label}
                        placeholder={placeholder}
                        type='button'
                        value={getDisplayValue()}
                        className={clsx(styles['select'], {
                            [styles['select-open']]: isOpen,
                        })}
                        postfixEl={
                            isLoading ? (
                                <div
                                    className={
                                        styles[
                                            'multi-select-list__multi-select-option-loading-wrapper'
                                        ]
                                    }
                                >
                                    <Spinner />
                                </div>
                            ) : (
                                <svg
                                    fill='none'
                                    height='7'
                                    viewBox='0 0 12 7'
                                    width='12'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        clipRule='evenodd'
                                        d='M11.7364 0.263993C11.3849 -0.0874781 10.8151 -0.0874767 10.4636 0.263996L6.00002 4.7276L1.53639 0.263993C1.18492 -0.0874777 0.615073 -0.0874762 0.263602 0.263997C-0.087869 0.61547 -0.087868 1.18532 0.263605 1.53679L5.36363 6.63679C5.7151 6.98826 6.28495 6.98826 6.63642 6.63679L11.7364 1.53679C12.0879 1.18531 12.0879 0.615464 11.7364 0.263993Z'
                                        fill='#323942'
                                        fillRule='evenodd'
                                    />
                                </svg>
                            )
                        }
                        onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            setIsOpen((state) => !state)
                        }}
                    />
                    {isOpen && (
                        <>
                            {mode === 'portal'
                                ? createPortal(renderContent(), document.body)
                                : renderContent()}
                        </>
                    )}
                </div>
            </div>
        )
    }
)

MultiSelect.displayName = 'MultiSelect'
